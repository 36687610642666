// src/Header.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import logo from './images/Circular_Logo.png';

function Header() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <header className="site-header">
      <div className="header-grid">
        <div className="header-left">
          <Link to="/" className="logo-link">
            <img src={logo} alt="Company Logo" className="logo" />
          </Link>
        </div>
        {!isHomePage && (
          <>
            <div className="header-center">
              <h1 className="company-name">Sierra Nevada Trailer Company</h1>
              <nav className="main-nav">
                <ul>
                  <li><Link to="/" className="menu-link">Home</Link></li>
                  <li><Link to="/browse-trailers" className="menu-link">Browse Trailers</Link></li>
                  {/* Removed the Trailer Finder */}
                  <li><Link to="/contact-us" className="menu-link">Contact Us</Link></li>
                </ul>
              </nav>
            </div>
            <div className="header-right">
              <div className="contact-info">
                <span className="phone-number">Call Us: (209) 602-8887</span>
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
